import React, { useState, useRef } from 'react';
import labBackground from './images/young_family.png';
import './App.css';

function App() {
  const [file, setFile] = useState(null);
  const [activeTab] = useState('main');
  const [showUploadSection, setShowUploadSection] = useState(false);

  const mainRef = useRef(null);
  const videoGalleryRef = useRef(null);
  const uploadRef = useRef(null);
  const contactRef = useRef(null);


  // Sample video URLs - replace these with your actual video URLs
  const videoUrls = [
    { url: "https://fertilityx-cdn-stage.azureedge.net/sperm_seach.mp4", title: "Standard Semen Sample" },
    { url: "https://fertilityx-cdn-stage.azureedge.net/advanced_search.mp4", title: "Azoospermia Case: Dense Sample with Multiple Cell Types" },
    { url: "https://fertilityx-cdn-stage.azureedge.net/many_sperms.mp4", title: "Tracking Multiple Sperm Cells in Field" },
    { url: "https://fertilityx-cdn-stage.azureedge.net/testicular_tissues.mp4", title: "Azoospermia Case: Testicular Tissue with Blood Cells" },
  ];

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const getTabStyle = (tabName) => ({
    marginRight: '10px',
    marginBottom: '5px',
    padding: '5px',
    color: '#7C91B5',
    cursor: 'pointer',
    fontWeight: activeTab === tabName ? 'bold' : 'normal',    
    fontSize: 'clamp(12px, 2vw, 16px)',
    height: 'clamp(30px, 6vw, 40px)',
    width: 'clamp(80px, 15vw, 120px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: '#E9DFE7',
    outline: 'none',    
  });

  const handleSubmit = async (e) => {
    e.preventDefault();    
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('microscopeType', e.target.microscopeType.value);

      try {
        const response = await fetch(`${apiUrl}/upload`, {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const result = await response.text();
          console.log('File uploaded successfully:', result);
          // You can add further actions here, like showing a success message
        } else {
          console.error('File upload failed');
          // You can add error handling here, like showing an error message
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        // You can add error handling here, like showing an error message
      }
    } else {
      console.log('No file selected');
    }
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="App" style={{ 
      backgroundColor: '#FFFFFF',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
      }}>
<header className="App-header" style={{ 
  backgroundColor: 'transparent',
  minHeight: '80px',
  flexShrink: 0
}}>
  <div className="header-container" >
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px',
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <div style={{
          fontSize: 'clamp(18px, 4vw, 32px)',
          fontWeight: '300',
          fontStyle: 'italic',
          fontFamily: 'cursive',
          color: 'var(--primary-blue-color)'
        }}>
          FertilityX.AI
        </div>        
      </div>
    </div>
    <nav style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      padding: '7px 10px',
    }}>
      <button onClick={() => scrollToSection(mainRef)} style={getTabStyle('main')}>Home</button>
      <button onClick={() => scrollToSection(videoGalleryRef)} style={getTabStyle('video_gallery')}>Demo</button>
      {showUploadSection && (
        <button onClick={() => scrollToSection(uploadRef)} style={getTabStyle('upload')}>Try it now</button>
      )}
      <button onClick={() => scrollToSection(contactRef)} style={getTabStyle('contact')}>Contact Us</button>
    </nav>
  </div>
</header>
        <div className="scrollable-content" style={{ 
        flex: 1,
        overflowY: 'auto',
        scrollBehavior: 'smooth'
      }}>
        <section ref={mainRef} style={{ minHeight: '100vh', padding: '20px' }}>
          {/* Main content */}
          <div style={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            maxWidth: '1200px',
            margin: '0 auto'
          }}>
            <div style={{ 
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}>
              <div style={{ 
                flex: '1 1 300px',
                minWidth: '300px',
                padding: '20px 20px 0 20px',
                textAlign: 'center'
              }}>
                <h1 style={{ 
                  fontSize: 'clamp(1.5em, 5vw, 2.5em)', 
                  color: 'var(--secondary-gray-color)' 
                }}>Welcome to FertilityX.AI</h1>
                          
                <div style={{
                  textAlign: 'left',
                  marginTop: '20px',
                  marginBottom: '20px'
                }}>
                  <p style={{
                    fontSize: 'clamp(1em, 3vw, 1.2em)',
                    color: 'var(--secondary-gray-color)',
                    fontWeight: 'bold',
                    marginBottom: '10px'
                  }}>
                    Our X Factor:
                  </p>
                  <ul style={{
                    listStyleType: 'none',
                    padding: 0,
                    margin: 0
                  }}>
                    <li style={{
                      fontSize: 'clamp(0.9em, 2.5vw, 1.1em)',
                      color: 'var(--secondary-gray-color)',
                      marginBottom: '5px'
                    }}>
                      • Efficient – Get results in less time
                    </li>
                    <li style={{
                      fontSize: 'clamp(0.9em, 2.5vw, 1.1em)',
                      color: 'var(--secondary-gray-color)',
                      marginBottom: '5px'
                    }}>
                      • Optimized – Designed for peak performance
                    </li>
                    <li style={{
                      fontSize: 'clamp(0.9em, 2.5vw, 1.1em)',
                      color: 'var(--secondary-gray-color)',
                      marginBottom: '5px'
                    }}>
                      • Effective – Practical, results-driven approach
                    </li>
                    <li style={{
                      fontSize: 'clamp(0.9em, 2.5vw, 1.1em)',
                      color: 'var(--secondary-gray-color)',
                      marginBottom: '5px'
                    }}>
                      • Revolutionizing - cutting-edge AI technology.
                    </li>
                  </ul>
                </div>
                <button 
  onClick={() => scrollToSection(videoGalleryRef)}
  className="watch-demo-button"
>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <div style={{ 
      height: '24px',
      width: '24px',
      marginRight: '10px'
     }}>
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="24" 
        height="24" 
        viewBox="0 0 24 24" 
        fill="white" 
      >
        <path d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"/>
      </svg>
    </div>
    <div>
      Watch Demo
    </div>
  </div>
</button>
                <div style={{
                  fontSize: 'clamp(24px, 4vw, 36px)',                  
                  color: 'var(--secondary-gray-color)',
                  paddingTop: '40px',
                  fontStyle: 'italic'
                }}>
                  We find it!
                </div>
              </div>
              <div style={{ 
                flex: '1 1 300px',
                minWidth: '300px',
                maxWidth: '500px', 
                padding: '20px',
                display: 'flex',
                justifyContent: 'center'
              }}>
                <img 
                  src={labBackground} 
                  alt="Lab background" 
                  style={{ 
                    width: '100%',
                    height: 'auto',
                    maxHeight: '300px',
                    objectFit: 'contain'
                  }} 
                />
              </div>
            </div>
          </div>
        </section>

        <section ref={videoGalleryRef} style={{ minHeight: '100vh', padding: '20px 20px 20px' }}>
          {/* Video Gallery content */}
          <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', paddingLeft: '0px' }}>
            <h2 style={{ width: '100%', textAlign: 'left', fontSize: '24px', fontWeight: 'bold' }}>Sperm Search Examples</h2>
            <div style={{ width: '100%', textAlign: 'left', fontSize: '18px', fontWeight: 'normal', disply: 'block' }}>
              <div style={{ marginBottom: '10px' ,display: 'flex', flexDirection: 'column' ,
                padding: '0 10px'

              }}>
                <div style={{ marginBottom: '10px' }}>Watch how FertilityX.AI helps identify and track sperm in various situations:</div>
                <div>When sperm is detected, our system marks it with a bounding box and keeps track of its movements.</div>
              </div>        
            </div>
            <div className="video-container">
              {videoUrls.map((video, index) => (
                <div key={index} className="video-item">
                  <h3 style={{ 
                    fontSize: '18px', 
                    marginBottom: '10px', 
                    textAlign: 'left',
                  }}>{video.title}</h3>
                  <video width="100%" controls muted>
                    <source src={video.url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ))}
            </div>
          </div>
        </section>

        {showUploadSection && (
        <section ref={uploadRef} style={{ minHeight: '100vh', padding: '20px 20px 20px' }}>
          {/* Upload content */}
          <form onSubmit={handleSubmit} style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
            padding: '20px',
            maxWidth: '700px',
            margin: '0 auto'
          }}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              marginBottom: '10px',
              color: '#000000',
              fontSize: '20px',
              fontWeight: 'bold',
              textAlign: 'center'
            }}>
              <label 
                htmlFor="microscopeType" 
                style={{
                  width: '40%',
                  marginRight: '10px',
                  textAlign: 'right',
                  fontWeight: 'bold'
                }}
              >
                Microscope Type:
              </label>
              <input 
                id="microscopeType"
                type="text" 
                name="microscopeType" 
                placeholder="Enter microscope type" 
                style={{
                  width: '60%',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ccc'
                }}
              />
            </div>
            <input 
              type="file" 
              onChange={handleFileChange}
              style={{
                width: '100%'
              }}
            />
            <button 
              type="submit"
              style={{
                padding: '10px 20px',
                backgroundColor: 'var(--secondary-gray-color)',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer'
              }}
            >
              Upload
            </button>
            </form>
        </section>
        )}
        <section ref={contactRef} style={{ minHeight: '50vh', padding: '120px 20px 20px' }}>
          <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px' }}>Contact Us</h2>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '50px' }}>
            <a href="https://www.linkedin.com/company/fertilityx" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img src="https://cdn-icons-png.flaticon.com/512/174/174857.png" alt="LinkedIn" style={{ width: '50px', height: '50px' }} />
                <span style={{ marginTop: '10px', color: '#0077B5' }}>LinkedIn</span>
              </div>
            </a>
            <a href="https://forms.gle/DN9j7aSeW942QHVL6" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img src="https://upload.wikimedia.org/wikipedia/commons/5/5b/Google_Forms_2020_Logo.svg" alt="Google Forms" style={{ width: '50px', height: '50px' }} />
                <span style={{ marginTop: '10px', color: '#673AB7' }}>Contact Form</span>
              </div>
            </a>
          </div>
        </section>
      </div>
        
      <footer style={{ 
  backgroundColor: '#FFFFFF',
  padding: '10px',
  color: '#7a7979',
  fontSize: '14px',
  flexShrink: 0
}}>
  <div className="footer-content">
    <p>This product is for experimental use only and is not yet commercially available.</p>
    <p>We appreciate your understanding as we continue to build and refine our platform.</p>
  </div>
</footer>
    </div>
  );
}

export default App;